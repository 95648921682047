import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import slugify from 'slugify';
import styles from './Home.module.css';
//import ListsWidget from '../ListsWidget/ListsWidget';


function Home() {
  const [data, setData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    axios.get('https://songs.margo.link/api/get_data/')
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [location]);

  return (
    <div>
      <h1>Welcome to My Song Application</h1>
      <p>This is the main page of the application.</p>
      <div>
        <h2>Fetched Data:</h2>
        <ul>
          {data.map((item) => (
            <li key={item.id}>
              <Link to={`/songs/${slugify(item.title)}/${slugify(item.artist)}`}>
                  {item.title} by {item.artist}
                </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Home;