import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/main/Home/Home';
import SongDetail from './components/main/SongDetail/SongDetail';

function App() {

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/songs/:songName/:artist" element={<SongDetail />} />
      </Routes>
    </Router>
  );
}

export default App;
