import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import styles from './SongDetail.module.css';

function SongDetail() {
  const [song, setSong] = useState(null);
  const [tonality, setTonality] = useState(null);
  const [chordModal, setChordModal] = useState({ visible: false, position: { left: 0, top: 0 } });
  const { songName, artist } = useParams();

  useEffect(() => {
    const decodedSongName = decodeURIComponent(songName);
    const decodedArtist = decodeURIComponent(artist);

    axios.get(`https://songs.margo.link/api/songs/${decodedSongName}/${decodedArtist}/`)
      .then((response) => {
        setSong(response.data);
        setTonality(response.data.original_key);
      })
      .catch((error) => {
        console.error('Error fetching song details:', error);
      });
  }, [songName, artist]);

  if (!song) {
    return <div>Loading...</div>;
  }

  const notes = ['Ab', 'A', 'A#', 'Bb', 'B', 'C', 'C#', 'Db', 'D', 'D#', 'Eb', 'E', 'F', 'F#', 'Gb', 'G', 'G#'];

  function getTransposeInterval(originalKey, newKey) {
    const originalIndex = notes.indexOf(originalKey);
    const newIndex = notes.indexOf(newKey);
    return newIndex - originalIndex;
  }

  function handleChordModalChange(chord, e, shouldOpen) {
    const encodedChord = encodeURIComponent(chord);
    const chordElement = e.target;
    const rect = chordElement.getBoundingClientRect();

    // Get the scroll offsets
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Calculate positions with respect to the document
    const topPosition = rect.top + rect.height + scrollTop;
    const leftPosition = rect.left + scrollLeft;

    setChordModal({
      visible: shouldOpen,
      guitarImagePath: `/images/guitar_chords/${encodedChord}.png`,
      pianoImagePath: `/images/piano_chords/${encodedChord}.png`,
      position: shouldOpen ? {
        left: leftPosition,
        top: topPosition
      } : chordModal.position
    });
  }

  function handleTonalityChange(key) {
    setTonality(key);
    setChordModal({ ...chordModal, visible: false });
  }

  function renderChordLine(lyricLine, chordsArray) {
    let transpositionInterval = 0;
    if (tonality && song.original_key) {
      transpositionInterval = getTransposeInterval(song.original_key, tonality);
    }

    let chordElements = chordsArray.map(([chord, position], index) => {
      let baseChord = chord.replace(/m$/, '');
      let chordQuality = chord.includes('m') ? 'm' : '';
      let originalChordIndex = notes.indexOf(baseChord);
      let newChordIndex = originalChordIndex + transpositionInterval;

      if (newChordIndex >= notes.length) newChordIndex -= notes.length;
      if (newChordIndex < 0) newChordIndex += notes.length;

      const transposedChord = notes[newChordIndex] + chordQuality;
      const leftOffset = `${position}ch`;

      return (
        <span
          key={`chord-${index}`}
          className={styles.chord}
          onMouseEnter={(e) => handleChordModalChange(transposedChord, e, true)}
          onMouseLeave={(e) => handleChordModalChange(transposedChord, e, false)}
          style={{ left: leftOffset, position: 'absolute' }}
        >
          {transposedChord}
        </span>
      );
    });

    return (
      <div className={styles.lineWrapper}>
        <span className={styles.lyrics}>{lyricLine}</span>
        {chordElements}
      </div>
    );
  }

  function renderChordData() {
    if (chordModal && chordModal.visible) {
      return (
        <div
          className={styles.chordDataDiv}
          style={{
            position: 'absolute',
            left: `${chordModal.position.left}px`,
            top: `${chordModal.position.top}px`
          }}
        >
          <img src={chordModal.guitarImagePath} alt="Guitar chord" />
          <img src={chordModal.pianoImagePath} alt="Piano chord" />
        </div>
      );
    }
    return null;
  }

  function currentTonality() {
    return (
      <>
        <div className="m-5 d-flex flex-wrap">
          {notes.map((note) => (
            <div
              key={note}
              className={styles.tonalityButton}
              onClick={() => handleTonalityChange(note)}
            >
              {note}
            </div>
          ))}
        </div>
        <div>
          <p>Current Key: {tonality}</p>
        </div>
      </>
    );
  }

  const renderSong = song.chords.map((object, index) => {
    const lyricLine = Object.keys(object)[0];
    const chordsArray = object[lyricLine];
    return (
      <div key={index}>
        {renderChordLine(lyricLine, chordsArray)}
        <p className={styles.lyrics_line}>{lyricLine}</p>
      </div>
    );
  });

  return (
    <div>

      <div id="song-info">
        <h1>{song.artist} - {song.title} – Chords Lyrics How To Play Guitar Strumming Fingerpicking – Video Tutorial – Piano Keyboard Chords</h1>
      </div>

      <nav className={styles.navMenu}>
        <a href="#song-info">Song Info</a>
        <a href="#lyrics-chords">Lyrics & Chords</a>
        <a href="#tonality">Tonality</a>
        <a href="#video-tutorials">Video Tutorials</a>
        <a href="#comments-section">Comments</a>
      </nav>

      <div id="tonality">
        {currentTonality()}
      </div>

      <div id="lyrics-chords">
        <div>
          <p>lyrics with chords</p>
        </div>
        <div>
          {renderSong}
        </div>
      </div>

      <div id="video-tutorials">
        <p>original song video</p>
        <p>guitar strumming pattern video tutorial</p>
        <p>guitar fingerpicking pattern video tutorial</p>
      </div>

      <div id="comments-section">
        <p>write your comments or questions</p>
      </div>

      {renderChordData()}
    </div>
  );
}

export default SongDetail;
